//=== Fonts
//@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');
//@import url('https://fonts.googleapis.com/css?family=Raleway');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

//=== Arquivos
@import "_mixins.scss";
@import "_cores.scss";
@import "_header.scss";
@import "_footer.scss";
@import "_main.scss";
@import "_aux.scss";

body {
  font-family: 'Open Sans', sans-serif;
    background: #eeeeee;
}

#request-message {
  display: none;
  margin-top: 10px;
  margin-bottom: 0;
  
  .alert {
    margin-bottom: 0;
  }
}

section {
  clear: both;
}

header, main, footer {
  transform: none;
  transition: 0.5s;
}

header.active, main.active, footer.active {
  transform: translateX(270px);
}

.container {
  width: 1430px !important;
  max-width: 100%;
}

.sidebar {
  position: fixed;
  height: 100%;
  background: $secundaria;
  width: 270px;
  z-index: 999999;
  transform: translateX(-100%);
  transition: 0.5s;
  &.active {
    transform: translateX(0%);
  }
  nav {
    ul {
      list-style: none;
      padding: 0px;
      li {
        a {
          width: 100%;
          display: block;
            
          height: 50px;
          text-decoration: none;
          text-align: left;
          text-transform: uppercase;
          font-family: 'Open Sans', sans-serif;
          @include font(1.6rem, white, 600, 2.1rem);
          display: inline-block;
          padding: 16px 16px 15px 20px;
          transition: 0.3s;
          border-bottom: 1px solid rgba(255, 255, 255, 0.15);
          span {
            float: right;
            .fa {
              transition: 0.5s;
              &.active {
                transform: rotate(-90deg);
              }
            }
          }
        }
        .sub-menu {
          // height: 0px;
          // overflow: hidden;
          display: none;
          a {
            padding-left: 30px;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  header {
    .sub-header {
      display: none;
    }
    .menu {
      .nav-responsive {
        display: block;
      }
      nav {
        display: none;
        ul {
          li {
            a {
            }
          }
        }
      }
    }
  }
  .container {
    width: 970px !important;
  }
  .col-dir {
    width: 100% !important;
    padding-left: 0px !important;
  }
  .col-esq {
    width: 100% !important;
  }
  .pre-destaque {
    display: none;
  }
}

@media (max-width: 1030px) {
  .container {
    width: 100% !important;
    padding: 30px;
    max-width: 768px;
  }
  main .corpo .col-esq .triple-noticias .box-noticiascat {
    width: calc(100% / 2);
  }
  footer {
    .col-footer {
      width: calc(100% / 2);
      &:first-child {
        width: 100%;
        text-align: center;
        .logo {
          max-width: 100%;
          display: inline-block;
            img{
                width: 100%;
            }
        }
      }
      
    }
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 375px;
    width: 100% !important;
    clear: both;
    padding: 10px;
  }
  main .corpo .col-esq .triple-noticias .box-noticiascat {
    width: calc(100%);
    .boxnoticial {
      .cont-img {
        height: auto !important;
      }
    }
  }
  footer {
    .col-footer {
      width: calc(100%);
      .boxnoticial {
        .cont-img {
          height: auto !important;
        }
      }
    }
  }
}
