footer{
  background: $footer;
  .col-footer{
    width: calc(100% / 3);
    display: inline-block;
    float: left;
    padding: 40px 15px;
    color: #dddddd;
    .title-footer{
      h2{
        @include font(16px,inherit,bolder);
        border-bottom: 2px solid rgba(80, 80, 80, 0.4);
        margin: 0px;
        padding: 15px 0px;
        text-transform: capitalize;
      }
    }
    .logo{
      width: 300px;

      img{
        display: inline-block;
        width: 100%;
      }
    }
    .sobre{
      padding-top: 15px;
    }
    .boxnoticial{
      @include boxnoticial(80px,60px, 15px);
      figcaption{
        h2{
          a{
            color: #dddddd;
          }
        }
      }
    }
  }
  .posfooter{
    background: rgba(0, 0, 0, .5);
    padding: 15px 0px;
    @include font(15px ,#dddddd);
    p{
      margin: 0px;
    }
  }
}
