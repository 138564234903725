
.boxnoticiah{
      .link_over1{
          color: #333;
       }       
       img{
        padding:2px;
        background:#fff;
        border:1px solid #ccc;
       }
}

.boxnoticiah:hover{
       .link_over1{
        color:#408cd0!important;
       } 
       img{
        background:#408cd0;
       }
}



.boxnoticial{
      .link_over1{
          color: #333;
       }       
       img{
        padding:2px;
        background:#fff;
        border:1px solid #ccc;
       }
}

.boxnoticial:hover{
       .link_over1{
        color:#408cd0!important;
       } 
       img{
        background:#408cd0;
       }
}

main .destaques .boxdestaque2 .overlay{
  background:none !important;
}

main .corpo .col-dir .categorias nav ul li:hover a{
        color:#408cd0;
}
main .corpo .col-dir .categorias nav ul li:hover span{
  background:#408cd0!important;
}



.share-bar{
  list-style: none;
  padding-left: 0px;
}
.share-bar li{
  padding-bottom: 10px;
  display: inline-block;
}

.whatsapp{
  background: #1fa67a;
  color: white;
  padding: 5px;
  text-decoration: none;
  font-weight: bolder;
  border-radius: 3px;
  display:none;

}

.whatsapp:hover{
  color: white;

}
@media (max-width: 991px){
  .whatsapp{
    display: inline-block;
  }
}



.pagination{
  .current{
    a{
    background: #003068 !important;
    color: #fff;
  }
  }
}

.img_not{
  width: 100%;
  height: 300px;
  position:relative;
  overflow: hidden;
  img{
    top: -100%;
    left: 0px;
    height: auto;
    width: 100%;
    position:absolute;
  }
  .preto{
    position:absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 10;
  }
}

.img_100{
  width: 100% !important;
  max-height: none !important;
}
.mb_0{
  margin-bottom: 0px;
}

@media (min-width: 1201px){
  .sidebar{
    display:none;
  }
}
@media (max-width:1200px) {
  header{
    .pre-header{

    }
    .sub-header{

    }
    .menu{
      nav{
        ul{
          li{
            a{
              @include font(9px);
            }
            .submenu{
              nav{
                ul{
                  li{
                    a{
                      @include font(8px);
                      padding: 10px 15px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width:991px) {
  header{
    .pre-header{
    }
    .sub-header{
      border-bottom: 4px solid $primaria;
      overflow: hidden;
    }
    .menu{
      // nav{
      //   position: fixed;
      //   z-index: 9999;
      //   height: calc(100% - 156.5px);
      //   width: 300px;
      //   background: $primaria;
      //   left: 0px;
      //   ul{
      //     li{
      //       display: block;
      //       width: auto;
      //       float: none;
      //       a{
      //         text-align: left;
      //       }
      //       .submenu{
      //         display: none;
      //         nav{
      //           ul{
      //             li{
      //               a{
      //               }
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}

@media (max-width:770px) {
  main .corpo .col-esq .noticia-interna .boxnoticiain figcaption .desc-right{
        width: 100% !important;
  }

  main .corpo .col-esq .noticia-interna .boxnoticiain figcaption .desc-left{
        width: 100% !important;
  }
  main .corpo .col-esq .noticia-interna .boxnoticiain figcaption .desc-left .compartilhar a{
        float: left !important;
  }
}
@media (max-width: 400px) {
  main .corpo .col-esq .triple-noticias .box-noticiascat, main .corpo .populares-semana .boxnoticiah{
    width: 100%;
  }
  footer .col-footer{
    width: 100%;
    &:not(:first-child){
      padding-top: 0;
    }
  }
}

