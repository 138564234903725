main {
    .ler_texto{
        padding:10px 40px;
    }
    .pre-destaque {
        .boxsimplescirculo {
            @include boxsimplescirculo(6, 35px, false);
        }
    }
    .destaques {
        &.destaque-slider {
            width: 70%;
            float: left;
            .slick-dots {
                position: absolute;
                bottom: 10px;
                display: block;
                padding: 0;
                list-style: none;
                text-align: center;
                right: 0;
                width: auto;
                li {
                    &.slick-active {
                        button {
                            transform: scale(1.3);
                        }
                    }
                    button {
                        transition: 0.5s;
                        &::before {
                            font-size: 10px;
                        }
                    }
                }
            }
            @media (max-width: 1250px) {
                width: 100%;
            }
        }
        .boxdestaque {
            @include boxdestaque(520px, 30px, 4, 0.4);
        }
        .boxdestaque2 {
            @include boxdestaque(520px, 30px, 1, 0.3);
            width: 70%;
            float: none;
            img {
                max-height: 100%;
                min-width: 100%;
                -webkit-transition: all 300ms linear;
                -moz-transition: all 300ms linear;
                -ms-transition: all 300ms linear;
                -o-transition: all 300ms linear;
                transition: all 300ms linear;
            }
            figcaption {
                transform: translateY(0);
                max-width: none;
                min-width: none;
                width: 100%;
                left: 0;
                bottom: 0;
                padding: 15px;
                h2 {
                    @include font(40px, white, 700);
                    color:#bbb;
                    text-shadow: 1px 1px 1px #000;
                    -webkit-transition: all 300ms linear;
                    -moz-transition: all 300ms linear;
                    -ms-transition: all 300ms linear;
                    -o-transition: all 300ms linear;
                    transition: all 300ms linear;

                }
                .desc {
                    display: none;
                }
            }
            a:hover{
                h2{
                    color: #fff;
                }
                img{
                        max-height: 110%;
                        min-width: 110%;
                }
            }
            @media (max-width: 1250px) {
                width: 100%;
                height: 520px;
                figcaption {
                    h2 {
                        font-size: 35px;
                    }
                }
            }
            @media (max-width: 768px) {
                width: 100%;
                height: 300px;
                figcaption {
                    h2 {
                        font-size: 20px;
                    }
                }
            }
            @media (max-width: 1030px) {
                width: 100%;
                height: 520px;
                figcaption {
                    h2 {
                        font-size: 20px;
                    }
                }
            }
        }
        &.destaque-right {
            display: inline-block;
            width: 30%;
            padding-left: 4px;
            @media (max-width: 1250px) {
                width: 100%;
                .boxdestaque2 {
                    @include boxdestaque(300px, 30px, 1, 0.3);
                }
            }
            .boxdestaque2 {
                @include boxdestaque(258px, 30px, 1, 0.3);
                margin-bottom: 4px;
                figcaption {
                    transform: translateY(0);
                    max-width: none;
                    min-width: none;
                    width: 100%;
                    left: 0;
                    bottom: 0;
                    padding: 15px;
                    h2 {
                        @include font(20px, white, 700);
                        color:#bbb;
                    }

                }

                    a:hover{
                        h2{
                            color: #fff;
                        }
                        img{
                                max-height: 110%;
                                min-width: 110%;
                        }
                    }


            }
        }
    }
    .box-video {
        iframe {
            width: 100%;
        }
    }
    .corpo {
        padding: 15px;
        padding-top: 40px;
        background: #f9f9f9;
        margin-bottom: 15px;
        // min-height: 5050px;
        .col-esq {
            width: calc(100% / 2 + 25%);
            float: left;
            .ultimas-noticias {
                padding-top: 15px;
                .title-section {
                    @include title-categoria($primaria);
                    margin-top: 0px;
                    padding-top: 0;
                }
                .boxnoticiah {
                    @include boxnoticiah(285px, 2);
                }
                
            }
            .entretenimento {
                .title-section {
                    @include title-categoria($entretenimento);
                }
                .boxnoticiah {
                    @include boxnoticiah(185px, 3);
                }
            }
            .populares {
                // .title-section{
                //   @include title-categoria($entretenimento);
                // }
                .boxnoticiah {
                    @include boxnoticiah(185px, 3);
                }
            }
            .triple-noticias {
                margin-top: 15px;
                .box-noticiascat {
                    padding: 0px 15px;
                    display: inline-block;
                    float: left;
                    width: calc(100% / 3);
                    .boxnoticiac {
                        @include boxnoticiac(200px, 15px, 1, 0.5);
                    }
                    aside {
                        .boxnoticial {
                            @include boxnoticial(80px, 60px, 15px);
                        }
                    }
                }
            }
            .artigos {
                padding-bottom: 15px;
                .boxnoticial {
                    padding-bottom: 40px;
                    width: 100%;
                    @include boxnoticial(320px, 180px, 20px);
                }
            }
            .comentario {
                // padding: 0
                padding: 0px 15px;
            }
            .noticia-interna {
                padding-bottom: 15px;
                .boxnoticiain {
                    padding-bottom: 40px;
                    float: left;
                    display: inline-block;
                    padding: 0px 15px;
                    margin-bottom: 20px;
                    .cont-img {
                        width: 100%;
                        position: relative;
                        transition-duration: 0.5s;
                        overflow: hidden;
                        margin: 15px 0px;
                        img {
                            // position: absolute;
                            top: 0px;
                            left: 0px;
                            z-index: 5;
                            max-width: 100%;
                        }

                    }
                    figcaption {
                        margin-top: 10px;
                        .categoria {
                            text-transform: uppercase;
                            @include font(1.3rem, false, 400);
                            padding: 5px 0px;
                            border-radius: 2px;
                        }
                        h2 {
                            @include font(3rem, #333333, 600, 1.6em);
                            margin-top: 10px;
                            margin-bottom: 3px;
                            a {
                                @include link(none, #333333);
                            }
                        }
                        .detalhes {
                            display: inline-flex;
                            @include font(14px, #aaaaaa, 300);
                        }
                        .desc-left {
                            float: left;
                            width: 200px;
                            text-align: center;
                            margin-top: 10px;
                            .compartilhar {
                                display: inline-block;
                                @include font(1.5rem, #333333);
                                a {
                                    display: block;
                                    .face {
                                        color: #3b5998;
                                    }
                                    .twitter {
                                        color: #00aced;
                                    }
                                    .whatsapp {
                                        color: #34af23;
                                    }
                                }
                            }
                        }
                        .desc-right {
                            width: calc(100% - 200px);
                            float: right;
                            @include font(16px, #333333, false, 2.2rem);
                            transition-duration: 1s;
                            margin-top: 10px;
                            h3 {
                                @include font(1.9rem, #333333, bolder, 2.7rem);
                                margin-top: 0px;
                            }
                            p {
                                @include font(1.6rem, #333333, normal, 2.6rem);
                            }
                        }
                    }
                }


            }
            .carregar-mais {
                margin-top: 15px;
                text-align: center;
                padding: 30px 0;
                border-top: 1px solid #e2e2e2;
                .vermais {
                    background-color: $primaria;
                    padding: 10px 20px;
                    display: inline-block;
                    text-decoration: none;
                    color: white;
                }
            }
            .vermais {
                background-color: $primaria;
                padding: 10px 20px;
                display: inline-block;
                text-decoration: none;
                color: white;
                border: none;
                margin-top: 15px;
            }
            .contato {
                padding: 0px 15px;
                form {
                    margin-top: 40px;
                    input {
                        width: 100%;
                        padding: 10px;
                        border-color: rgba(68, 68, 68, 0.2);
                        margin-bottom: 1rem;
                        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
                        font-size: 100%;
                        border-radius: 2px;
                    }
                    .input-gp {
                        font-size: 1.6rem;
                        color: #444444;
                        line-height: 2.6rem;
                        label {
                            font-weight: 400;
                        }
                    }
                    textarea {
                        height: 200px;
                    }
                }
            }
        }
        .col-dir {
            width: calc(100% / 2 - 25%);
            float: left;
            padding-left: 15px;
            .camacari {
                padding-top: 25px;
                .boxnoticiah {
                    @include boxnoticiah(185px, 1);
                    padding: 0px;
                    margin-bottom: 15px;
                }
            }
            .categorias {
                nav {
                    ul {
                        list-style: none;
                        padding: 0px;
                        li {
                            margin-bottom: 10px;
                            span {
                                width: 30px;
                                height: 30px;
                                text-align: center;
                                vertical-align: middle;
                                display: table-cell;
                            }
                            a {
                                padding: 0px 10px;
                                vertical-align: middle;
                                text-decoration: none;
                                color: $fonte;
                                display: table-cell;
                            }
                        }
                    }
                }
            }
        }
        .populares-semana {
            // .title-section{
            //   @include title-categoria($entretenimento);
            // }
            .boxnoticiah {
                margin-bottom: 15px;
                @include boxnoticiah(180px, 4);
            }
        }
    }
    .banner {
        padding: 15px;
        text-align: center;
        img {
            display: inline-block;
            max-width: 100%;
        }
    }
    .title-section {
        @include title-categoria($primaria);
        &.entrevista {
            @include title-categoria($entrevista);
        }
        &.entretenimento {
            @include title-categoria($entretenimento);
        }
        &.saude {
            @include title-categoria($saude);
        }
        &.esportes {
            @include title-categoria($esportes);
        }
        &.viagem {
            @include title-categoria($viagem);
        }
    }
}

.categoria {
    @include categoria(white, #000000);
    &.default {
        @include categoria(#000000, #ffffff)
    }
    &.entrevista {
        @include categoria($entrevista, white);
    }
    &.entrevista-i {
        @include categoria(transparent, $entrevista);
    }
    &.entretenimento {
        @include categoria($entretenimento, white);
    }
    &.entretenimento-i {
        @include categoria(transparent, $entretenimento);
    }
    &.saude {
        @include categoria($saude, white);
    }
    &.saude-i {
        @include categoria(transparent, $saude);
    }
    &.esportes {
        @include categoria($esportes, white);
    }
    &.esportes-i {
        @include categoria(transparent, $esportes);
    }
    &.viagem {
        @include categoria($viagem, white);
    }
    &.viagem-i {
        @include categoria(transparent, $viagem);
    }
    &.celebridade {
        @include categoria($celebridade, white);
    }
    &.celebridade-i {
        @include categoria(transparent, $celebridade);
    }
}

.list-galeria {
    clear: both;
    figure {
        padding: 2px;
        padding: 4px;
        a {
            border: 1px solid #dddddd;
            display: block;
            padding: 3px;
            &:hover {
                border-color: #0288d1;
            }
            border-radius: 4px;
            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
    }
}