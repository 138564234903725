@mixin pseudo($display: block, $pos: absolute, $content: '') {
  content: $content;
  display: $display;
  position: $pos;
}

@mixin push--auto {
  margin: {
    left: auto;
    right: auto;
  }
}

@mixin quadrado($tamanho,$cor) {
  width: $tamanho;
  height: $tamanho;
  display: inline-block;
  @if $cor {
    background-color: $cor;
  }
}

@mixin circulo($tamanho,$cor) {
  @include quadrado($tamanho, $cor);
  border-radius: 50%;
}

@mixin boxsimplescirculo($quantidade , $tamanho, $cor) {
  width: calc(100% / #{$quantidade} - 4px);
  display: inline-block;
  padding: 15px 0px;
  figure {
    @include circulo($tamanho, $cor);
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    img {
      // width: 200%;
      max-height: 100%;
    }
  }
  figcaption {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - (#{$tamanho} + 10px));
    h2 {
      @include font(1.2rem, #333333, false, 1.8rem);
      margin: 0px;
      
    font-family: 'Open Sans', sans-serif;

    }
  }
}

@mixin boxdestaque($altura , $padding, $quantidade, $overlay) {
  width: calc(100% / #{$quantidade});
  $overlay2: $overlay * 1.8;
  // float: left;
  display: inline-block;
  height: $altura;
  padding: $padding;
  position: relative;
  overflow: hidden;
  float: left;
  transition-duration: 0.5s;
  &:last-child {
    // float: right;
  }
  &.hover {
    width: calc(100% / (#{$quantidade} - 1) - 15px);
    figcaption {
      transform: translateY(0px);
      .desc {
        opacity: 1;
      }
    }
    .overlay {
      background-color: rgba(0, 0, 0, $overlay2);
    }
  }
  &.unhover {
    width: calc(100% / #{$quantidade} - 50px);
  }
  img {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 5;
    max-height: 100%;
  }
  span{
    color: #fcfcfc;
    font-size: 12px;
  }
  .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, $overlay);
    left: 0px;
    top: 0px;
    z-index: 6;
    transition-duration: 0.5s;
  }
  figcaption {
    position: absolute;
    z-index: 7;
    transform: translateY(130px);
    width: calc(100% - #{$padding} * 2);
    min-width: 120px;
    max-width: 282.5px;
    bottom: 0px;
    transition-duration: 0.5s;
    .categoria {
      text-transform: uppercase;
      @include font(1.3rem, false, 400);
      padding: 5px 8px;
      border-radius: 2px;
    }
    h2 {
      @include font(20px, white, 600);
      
    font-family: 'Open Sans', sans-serif;

    }
    .detalhes {
      @include font(14px, white, 300);
    }
    .desc {
      @include font(16px, white, false, 2.8rem);
      opacity: 0;
      height: 150px;
      transition-duration: 1s;
      margin-top: 10px;
      
    }
  }
  @media (max-width: 1250px) {
    width: calc(100% / 2);
    height: $altura;
    &.hover {
      width: calc(100% / 2);
    }
    &.unhover {
      width: calc(100% / 2);
    }
  }
  @media (max-width: 768px) {
    width: calc(100%);
    height: $altura;
    &.hover {
      width: calc(100%);
    }
    &.unhover {
      width: calc(100%);
    }
  }
  @media (max-width: 1030px) {
    figcaption {
      width: calc(100% - #{$padding} * 1);
      
      h2 {
        @include font(15px);
        
    font-family: 'Open Sans', sans-serif;

      }
    }
  }
}

@mixin boxnoticiac($altura , $padding, $quantidade, $overlay) {
  width: calc(100% / #{$quantidade});
  // float: left;
  display: inline-block;
  height: $altura;
  padding: $padding;
  position: relative;
  overflow: hidden;
  float: left;
  text-align: center;
  img {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 5;
    max-height: 100%;
  }
  .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, $overlay);
    left: 0px;
    top: 0px;
    z-index: 6;
    transition-duration: 0.5s;
  }
  figcaption {
    position: absolute;
    z-index: 7;
    width: calc(100% - #{$padding} * 2);
    bottom: 25px;
    .categoria {
      text-transform: uppercase;
      @include font(1.3rem, false, 400);
      padding: 5px 8px;
      border-radius: 2px;
    }
    h2 {
      @include font(15px, white, 600, 2.3rem);
      
    font-family: 'Open Sans', sans-serif;

    }
    .detalhes {
      @include font(14px, white, 300);
    }
  }
}

@mixin boxnoticiah($altura , $quantidade) {
  width: calc(100% / #{$quantidade});
  float: left;
  display: inline-block;
  padding: 0px 15px;
  margin-bottom: 20px;
  .cont-img {
    height: $altura;
    position: relative;
    transition-duration: 0.5s;
    overflow: hidden;
    img {
      // position: absolute;
      top: 0px;
      left: 0px;
      z-index: 5;
      max-height: 100%;
    }
  }
  figcaption {
    margin-top: 10px;
    .categoria {
      text-transform: uppercase;
      @include font(1.3rem, false, 400);
      padding: 5px 0px;
      border-radius: 2px;
      
    }
    h2 {
      @include font(20px, #333333, 600);
      margin-top: 10px;
      margin-bottom: 3px;
      
    font-family: 'Open Sans', sans-serif;

      a {
        @include link(none, #333333);
      }
    }
    .detalhes {
      @include font(14px, #aaaaaa, 300);
    }
    .desc {
      @include font(16px, #333333, false, 2.2rem);
      transition-duration: 1s;
      margin-top: 10px;
    }
  }
  @if ($quantidade == 4) {
    @media (max-width: 1030px) {
      width: calc(100% / (#{$quantidade} / 2));
    }
  }
  @media (max-width: 768px) {
    width: calc(100%);
  }
}

@mixin boxnoticial($larguraimg, $alturaimg, $fonte : 20px) {
  // width: calc(100% / #{$quantidade});
  float: left;
  display: inline-block;
  margin-top: 10px;
  // padding: 0px 15px;
  a {
  
  }
  .cont-img {
    height: $alturaimg;
    position: relative;
    transition-duration: 0.5s;
    overflow: hidden;
    display: inline-block;
    width: $larguraimg;
    vertical-align: top;
    margin-right: 10px;
    img {
      top: 0px;
      left: 0px;
      z-index: 5;
      max-height: 100%;
    }
  }
  figcaption {
    display: inline-block;
    // margin-top: 10px;
    width: calc(100% - (#{$larguraimg} + 15px));
    
    .categoria {
      text-transform: uppercase;
      @include font(1.3rem, false, 400);
      padding: 5px 0px;
      border-radius: 2px;
      
    }
    h2 {
      @include font($fonte, #333333, 600);
      margin-top: 0px;
      font-family: 'Open Sans', sans-serif;

      a {
        @include link(none, #333333);
      }
    }
    .detalhes {
      @include font(14px, #aaaaaa, 300);
      display: inline;
    }
    .desc {
      @include font(16px, #333333, false, 2.2rem);
      transition-duration: 1s;
      margin-top: 10px;
    }
  }
  @media (max-width: 768px) {
    .cont-img {
      margin: 0px;
      width: 100%;
      margin-bottom: 10px;
      img {
        max-width: 100%;
        max-height: none;
      }
    }
    figcaption {
      width: calc(100%);
      
    }
  }
}

@mixin categoria($background,$color) {
  background-color: $background;
  color: $color;
}

@mixin title-categoria($cor) {
  padding-top: 10px;
  margin: 15px 0px;
  position: relative;
  .title-categoria {
    margin: 0px;
    background-image: url(/app/images/gplay.png);
    background-color: $cor;
    background-size: cover;
    color: #ffffff;
    display: block;
    padding: 10px;
    text-transform: capitalize;
    @include font(15px, white);
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
      i{
          padding-right:5px;
      }
    a {
        text-decoration: none;
        color: inherit;
    }
  }
  &::before {
    @include triangle($cor, down);
    bottom: -6px;
    left: 5px;
  }
  
}

@mixin triangle($color, $direction, $size: 6px, $position: absolute, $round: false) {
  @include pseudo($pos: $position);
  width: 0;
  height: 0;
  @if $round {
    border-radius: 3px;
  }
  @if $direction == down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
    margin-top: 0 - round( $size / 2.5 );
  } @else if $direction == up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    margin-bottom: 0 - round( $size / 2.5 );
  } @else if $direction == right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    margin-right: -$size;
  } @else if $direction == left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    margin-left: -$size;
  }
}

@mixin font($size: false, $colour: false, $weight: false,  $lh: false) {
  @if $size {
    font-size: $size;
  }
  @if $colour {
    color: $colour;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $lh {
    line-height: $lh;
  }
}

@mixin link($decoration:false,$colour:false,$size:false,$background:false,$padding:false) {
  @if $size {
    font-size: $size;
  }
  @if $colour {
    color: $colour;
  }
  @if $decoration {
    text-decoration: $decoration;
  }
  @if $background {
    background-color: $background;
  }
  @if $padding {
    line-height: $padding;
  }
}

/* Margins & Paddings */
$names: ('top', 'right', 'left', 'bottom');
@each $name in $names {
  @for $i from 0 through 4 {
    $calc: $i * 5;
    
    // Margins
    .margin-#{$name}-#{$calc} {
      margin-#{$name}: #{$calc + 'px'} !important;
    }
    
    // Paddings
    .padding-#{$name}-#{$calc} {
      padding-#{$name}: #{$calc + 'px'} !important;
    }
  }
}
