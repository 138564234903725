$primaria:#002744;
$secundaria:#002744;
$terciaria:#FF0000;
$quartenaria:#f4f5f6;
$quintenaria:#ffc100;
$fonte:#3b3b3b;
$footer:#222222;

//CATEGORIAS
$entrevista:#f06292;
$viagem:#8bc34a;
$entretenimento:#ba68c8;
$saude :#ffa726;
$esportes :#f44336;
$celebridade :#009688;
