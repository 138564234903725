.social-btns .btn,
    .social-btns .btn:before,
    .social-btns .btn .fa {        
        -webkit-transition: all 0.35s;
        transition: all 0.35s;
        -webkit-transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
        transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
    }
    .social-btns .btn:before {
        top: 90%;
        left: -110%;
    }
    .social-btns .btn .fa {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        vertical-align: middle!important;
        line-height: 30px;
    }
    .social-btns .btn.facebook:before {
        background-color: #3b5998;
    }
    .social-btns .btn.facebook .fa {
        color: #3b5998;
    }
    .social-btns .btn.twitter:before {
        background-color: #3cf;
    }
    .social-btns .btn.twitter .fa {
        color: #3cf;
    }
    .social-btns .btn.google:before {
        background-color: #dc4a38;
    }
    .social-btns .btn.google .fa {
        color: #dc4a38;
    }
    .social-btns .btn.dribbble:before {
        background-color: #f26798;
    }
    .social-btns .btn.dribbble .fa {
        color: #f26798;
    } 
    .social-btns .btn.google:before {
        background-color: #dc4a38;
    }
    .social-btns .btn.google .fa {
        color: #dc4a38;
    }
    .social-btns .btn.skype:before {
        background-color: #00aff0;
    }
    .social-btns .btn.skype .fa {
        color: #00aff0;
    }
    .social-btns .btn:focus:before,
    .social-btns .btn:hover:before {
        top: -10%;
        left: -10%;
    }
    .social-btns .btn:focus .fa,
    .social-btns .btn:hover .fa {
        color: #fff;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    .social-btns {
        position: fixed;
        display: inline-block;
//        float: right;
        margin: auto;
        font-size: 0;
        text-align: center;
        bottom: 30px;
        right: 20px;
        z-index: 10000;
      
//        float: right;
//        padding: 60px 0;
    }
    .social-btns .btn {
        background-color: #fff;
        width: 30px;
        height: 30px;
        margin: 0 10px;
        text-align: center;
        position: relative;
        overflow: hidden;
        border-radius: 28%;
        -webkit-box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
        box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
        opacity: 0.99;
        border: 0;
        cursor: pointer;
        display: block;
        padding: 0;
        margin-bottom: 10px;
    }

    .social-btns .btn:before {
        content: '';
        width: 120%;
        height: 120%;
        position: absolute;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .social-btns .btn .fa {
        font-size: 20px;
        vertical-align: middle;
    }
header {
    .pre-header {
        padding: 10px 0px;
        @include font(14px, white, bolder);
        .frase {
            display: inline-block;
            p {
                margin: 0px;
            }
        }
        .sociais {
            display: inline-block;
            float: right;
            a {
                text-decoration: none;
                @include font(14px, white, bolder);
            }
        }
    }
    
    /*
  .fix_head{
      position:relative;
      .social{
        position:absolute;
        right: 0px;
        bottom:0px;
        list-style: none;
      }
  }
*/
    .reff_busca {
        form {
            position: relative;
            display: none;
            position: absolute;
            top: 8px;
            right: 44px;
            width: 260px;
            input {
                text-indent: 15px;
                width: 78%;
                height: 35px;
                border: 1px solid #fff;
                float: left;
            }
            button {
                width: 20%;
                height: 35px;
                float: left;
                border: 1x solid #fff;
                background: $primaria;
                color: #fff;
            }
            button:hover {}
        }
        .ativo {
            display: block !important;
        }
    }
    .sub-header {
        background-color: #fff;
        //    padding: 20px 0px;
        padding-bottom: 0px;
        .cont-sub {
            position: relative;
//            overflow: hidden;
            .form-busca{
                padding: 15px;
                background: $primaria;
                display: inline-block;
                position: absolute;
                right: -15px;
                z-index: 9;
                transform: translateY(-100%);
                transition: 0.5s;
                &.active{
                    transform: translateY(0%);
                }
                input{
                    padding: 10px;
                    width: 300px;
                }
            }
            .logo {
                width: 270px;
                position: absolute;
                left: 0;
                max-width: calc(100% - 30px);
                display: inline-block;
                transform: translateY(-46%);
                background: white;
                border-radius: 0px;
                padding: 10px;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
                z-index: 999;
                .data{
                    width: 100%;
                    display: block;
                    font-size:12px;
                    text-align: center;
                    width: 100%;
                }
                img {
                    width: 100%;
                }

            }
            .banner {
                padding: 15px 0;
                width: auto;
                //        float: right;
                display: inline-block;
                height: 100%;
                // line-height: 120px;
                position: relative;
                span {
                    position: absolute;
                    float: right;
                    display: block;
                    right: 0px;
                    bottom: -32px;
                    color: #777;
                }
            }
            .cont-banner{
                width: 80%;
                float: right;
                text-align: center;
            }
        }
    }
    .menu {
        background-color: $primaria;
        position: relative;
        padding: 0px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
        z-index: 99;
        // z-index: 2;
        .nav-responsive {
            text-align: center;
            display: none;
            position: relative;
            .responsive_search {
                position: relative;
                display: none;
                position: absolute;
                top: 50%;
                right: 44px;
                width: 260px;
                transform: translateY(-50%);
                &.ativo {
                    display: block;
                }
                input {
                    text-indent: 15px;
                    width: 78%;
                    height: 35px;
                    border: 1px solid #fff;
                    float: left;
                }
                button {
                    width: 20%;
                    height: 35px;
                    float: left;
                    border: 1x solid #fff;
                    background: $primaria;
                    color: #fff;
                }
                button:hover {}
            }
            a {
                text-decoration: none;
                color: white;
                padding: 10px 0;
                font-size: 3rem;
                &.menu-trigger {
                    display: inline-block;
                    float: left;
                }
                &.search {
                    float: right;
                }
            }
        }
        .logo {
            width: 150px;
            display: inline-block;
            position: relative;
            img {
                width: 100%;
            }
            .data{
                
            }
        }
        .banner {
            padding: 25px 0;
        }
        nav {
            ul {
                text-align: right;
                list-style: none;
                margin: 0px;
                padding: 0px;
                .social {
                    a {
                        font-size: 1.2rem;
                        padding: 16px 8px;
                    }
                }
                li {
                    display: inline-block;
                    // float: left;
                    position: relative;
                    &:hover {
                        .sub-menu {
                            display: block;
                            opacity: 1;
                        }
                        a {
                            color: $fonte;
                            background: white;
                            &.busca{
                                background: transparent;
                                color: #fff;
                            }
                        }
                    }
                    &.active {
                        a {
                            background-color: $terciaria;
                        }
                        &:hover {
                            a {
                                color: $fonte;
                                background: white;
                            }
                        }
                    }
                    .sub-menu {
                        position: absolute;
                        width: 280px;
                        display: none;
                        opacity: 0;
                        transition: opacity 0.5s;
                        top: 100%;
                        // left: 0px;
                        padding: 10px 0px;
                        box-shadow: 0 1px 0 1px rgba(0, 0, 0, .1);
                        z-index: 999999;
                        background-color: #fff;
                        li {
                            width: 100%;
                            overflow: hidden;
                            a {
                                font-size: 12px;
                                background: none;
                                width: 100%;
                                padding: 10px 15px;
                                color: rgba(66, 66, 66, 0.7);
                                text-align: left;
                                background-color: none;
                                &:hover {
                                    transform: translateX(5px);
                                }
                            }
                        }
                    }
                    a {
                        text-decoration: none;
                        text-align: center;
                        text-transform: uppercase;
                        font-family: 'Open Sans', sans-serif;
                        @include font(12px, white, 600);
                        display: inline-block;
                        padding: 26px 13px;
                        width: 100%;
                        transition: 0.3s;
                        font-size: 1.3rem;
                        line-height: 1.8rem;
                    }
                }
            }
        }
    }
}